import { ClassType } from '@lingoda/graphql';
import { type BookingFilter } from './models';

export const BOOKING_FILTER_STORE_KEY = 'bookingFilter';
export const VARS_STORE_KEY = 'bookingVars';

export const DEFAULT_BOOKING_FILTER: BookingFilter = {
    timeSlots: {
        slots: [],
        extendHours: 0,
    },
    classType: ClassType.Group,
    modules: [],
    lessons: [],
    dates: [],
};
