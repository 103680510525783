import { ApolloLink, fromPromise } from '@apollo/client';
import { getAuthCheck } from '@lingoda/auth';
import { getLang } from '@lingoda/env';
import { config } from '@lingoda/config';

export default () =>
    new ApolloLink((operation, forward) =>
        fromPromise(getAuthCheck('')).flatMap(() => {
            operation.setContext({
                headers: {
                    Accept: 'application/json',
                    'X-Locale': getLang(),
                    'X-Release-Id': config.releaseId,
                },
            });

            return forward(operation);
        }),
    );
