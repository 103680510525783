import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import { default as Box } from '@mui/material/Box';

interface Props {
    iconKey: string;
}

export const LottieIcon = ({ iconKey }: Props) => {
    const animation = useLottieAnimation(iconKey);

    if (!animation) {
        return null;
    }

    return (
        <Box
            sx={{
                position: 'relative',
                width: 80,
                height: 80,
                marginTop: '-10px',
                marginBottom: '-10px',
            }}
        >
            <Lottie animationData={animation} loop={true} />
        </Box>
    );
};

const useLottieAnimation = (iconKey: string) => {
    const [animation, setAnimation] = useState<unknown>(null);

    useEffect(() => {
        const animationFileName = getAnimationFileName(iconKey);

        void import(`./animations/${animationFileName}.json`).then((newAnimation) => {
            setAnimation(newAnimation.default);
        });
    }, [iconKey]);

    return animation;
};

const getAnimationFileName = (iconKey: string) => {
    switch (iconKey) {
        case 'tadaCelebration':
            return 'tada-celebration';
        case 'binoculars':
            return 'binoculars';
        case 'fire':
            return 'fire';
        case 'okhand':
            return 'okhand';
        default:
            throw new Error(`Unknown lottie iconKey: ${iconKey}`);
    }
};
