import { config } from '@lingoda/config';
import { getLang } from '@lingoda/env';
import { getUrl } from '@lingoda/urls';
import { getAuthCheck } from './authChecks';
import { parseJSON } from './common';
import { setRequestProvider } from './request';

interface RequestHeaders {
    [key: string]: string;
}

const RETRY_DELAY_INITIAL = 1000;
const STATUS_CODES_BYPASS = [404];
const STATUS_CODES_UNAUTHORIZED = [401];

const isLoginUrl = (url: string) =>
    [getUrl('api_login_check'), getUrl('user_security_check')].includes(url);

interface Params {
    onUnauthorized: () => void;
}

export const initRequestWebProvider = ({ onUnauthorized }: Params) =>
    setRequestProvider(async <T>(url: string, params: RequestInit): Promise<T> => {
        try {
            await getAuthCheck(url);
        } catch {}

        const headers: RequestHeaders = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Locale': getLang(),
            'X-Requested-With': 'XMLHttpRequest',
            'X-Release-Id': config.releaseId,
        };

        params.headers = { ...headers, ...params.headers };
        params.credentials = 'include';

        const callFetch = () => fetch(url, params).then<T>(parseJSON);

        let tries = params.method === 'GET' ? 3 : 1;
        let retryDelayMs = RETRY_DELAY_INITIAL;

        return new Promise<T>((resolve, reject) => {
            const failure = (response: Response) => {
                tries--;
                if (!tries || STATUS_CODES_BYPASS.includes(response.status) || isLoginUrl(url)) {
                    reject(response);
                } else if (STATUS_CODES_UNAUTHORIZED.includes(response.status)) {
                    onUnauthorized();
                    reject(response);
                } else {
                    setTimeout(() => {
                        callFetchAgain();
                    }, retryDelayMs);

                    retryDelayMs = getNextRetryDelayMs(retryDelayMs);
                }
            };

            const callFetchAgain = () => {
                callFetch().then(resolve, failure);
            };
            callFetchAgain();
        });
    });

const getNextRetryDelayMs = (lastDelayMs: number) => lastDelayMs * 2;
